import React from "react";
import { Link } from "gatsby";
import { OurAwardsQuery } from "../../prismic/staticQueries/markets/index.js";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import award1 from "../../images/siteImages/forex/top-100.png";
import award2 from "../../images/siteImages/forex/retail-broker.png";
import award3 from "../../images/siteImages/forex/best-copy.png";
import award4 from "../../images/siteImages/forex/top-100-24.png";

const OurAwards = () => {
  const language = useSelector((state) => state.language);
  const OurAwardsData = OurAwardsQuery(language);
  console.log("language", OurAwardsData);
  const {
    all_payment_box,
    payment_heading
  } = OurAwardsData;

  const all_payment_icon = [
    award1, award2, award3, award4
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="sectionbg py-5 mt-0">
      <h2 className="pt-2 text-bold-md heading text-center">{payment_heading.text}</h2>
      <div className="container">
        <div className="row">
          {all_payment_box && all_payment_box.length > 0 && all_payment_box.map((paymentmap, paymentmapInd) => (
            <div className="col-lg-3 d-flex align-items-center justify-content-center" key={"paymentmapInd" + paymentmapInd}>
              <img alt="ddd" src={all_payment_icon[paymentmapInd]} style={{ width: "200px" }} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(OurAwards);